export const ApiEndpoints = {
  users: '/users',
  user: '/user',
  offers: '/v1/offers',
  requests: '/v1/requests',
  deals: '/deals',
  dealsV1: '/v1/deals',
  documents: '/v1/documents',
  legals: '/legals',
};
