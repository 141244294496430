import axios from '@/libs/axios';
import { ApiEndpoints } from '@/settings';

const endpoints = {
  all: `${ApiEndpoints.requests}/all`,
  open: `${ApiEndpoints.requests}/open`,
  own: `${ApiEndpoints.requests}/own`,
  current: `${ApiEndpoints.requests}/show`,
  offers: '/requests/offers',
};

export default {
  namespaced: true,
  state: {
    currentRequest: {},
    requests: [],
    meta: {},
  },
  mutations: {
    SET_REQUESTS(state, requests = []) {
      state.requests = requests;
    },
    SET_CURRENT_REQUESTS(state, request = {}) {
      state.currentRequest = request;
    },
    SET_META(state, meta = {}) {
      state.meta = meta;
    },
  },
  actions: {
    async fetch({ commit }, reqMeta) {
      const { data } = await axios.get(
        endpoints[reqMeta.fetchParam],
        {
          params: reqMeta.data,
        },
      );

      commit('SET_META', data.meta);
      commit('SET_REQUESTS', data.data);
    },
    async fetchRequestById({ commit }, requestId) {
      const { data } = await axios.get(`${ApiEndpoints.requests}/${requestId}/show`);

      commit('SET_CURRENT_REQUESTS', data.data);
    },
    async fetchOffersById(_, requestId) {
      const { data } = await axios.get(`${endpoints.offers}/${requestId}`);

      return data.data.offers;
    },
    async updateRequest(_, { requestId, payload }) {
      await axios.put(`requests/update/${requestId}`, payload);
    },
  },
};
