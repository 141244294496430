import i18n from '@/libs/i18n';

export default [
  {
    path: '/apps/users/list',
    name: 'apps-users-list',
    meta: {
      pageTitle: i18n.t('users'),
      requiresAuth: true,
    },
    component: () => import('@/views/apps/user/users-list/UsersList.vue'),
  },
  {
    path: '/apps/users/view/:id',
    name: 'apps-users-view',
    meta: {
      pageTitle({ params: { id } }) {
        return i18n.t('showUserById', { userId: id });
      },
      requiresAuth: true,
    },
    component: () => import('@/views/apps/user/users-view/UsersView.vue'),
  },
  {
    path: '/apps/users/edit/:id',
    name: 'apps-users-edit',
    meta: {
      pageTitle({ params: { id } }) {
        return i18n.t('editUserById', { userId: id });
      },
      requiresAuth: true,
    },
    component: () => import('@/views/apps/user/users-edit/UsersEdit.vue'),
  },
  {
    path: '/apps/notifications',
    name: 'apps-notifications',
    meta: {
      pageTitle: i18n.t('notifications'),
      requiresAuth: true,
    },
    component: () => import('@/views/apps/notifications/Notifications.vue'),
  },
];
