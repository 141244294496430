import i18n from '@/libs/i18n';

export const resolveDate = date => {
  if (date === null) {
    return i18n.t('unlimited');
  }
  const getDate = new Date(date);
  const day = getDate.getDate() < 9 ? `0${getDate.getDate()}` : getDate.getDate();
  const month = (getDate.getMonth() + 1) < 9 ? `0${(getDate.getMonth() + 1)}` : getDate.getMonth() + 1;
  return `${day}.${month}.${getDate.getFullYear()}`;
};
