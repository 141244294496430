import Vue from 'vue';
import i18n from '@/libs/i18n';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default function updateDealHandler(response, commit, message) {
  if (response.status) {
    commit('SET_DEAL', response.data.deal || response.data.data);
    Vue.$toast({
      component: ToastificationContent,
      props: {
        title: message || i18n.t('changesSaved'),
        icon: 'CheckCircleIcon',
        variant: 'success',
      },
    });
  }
  if (!response.status) {
    Vue.$toast({
      component: ToastificationContent,
      props: {
        title: response.message,
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    });
  }
}
