import Vue from 'vue';
import Vuex from 'vuex';

// Modules
import app from './app';
import appConfig from './app-config';
import verticalMenu from './vertical-menu';
import users from './users';
import profile from './profile';
import offers from './offers';
import requests from './requests';
import deal from './deal';
import products from './products';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    users,
    profile,
    offers,
    requests,
    deal,
    products,
  },
  strict: process.env.DEV,
});
