import Vue from 'vue';
import VueI18n from 'vue-i18n';
import messages from '@/i18n/locales';

Vue.use(VueI18n);

export default new VueI18n({
  locale: localStorage.getItem('locale') || 'en',
  fallbackLocale: 'ru',
  messages,
});
