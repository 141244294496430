import axios from '@/libs/axios';
import { ApiEndpoints } from '@/settings';
import Vue from 'vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

const endpoints = {
  verified: '/offers/verified',
  all: `${ApiEndpoints.offers}/all`,
  open: `${ApiEndpoints.offers}/open`,
  own: `${ApiEndpoints.offers}/own`,
  accept: '/offers/accept',
  current: `${ApiEndpoints.offers}/show`,
  legals: '/offers/legal-members',
  'own-with-requests': `${ApiEndpoints.offers}/private`,
};

export default {
  namespaced: true,
  state: {
    currentOffer: {},
    offers: [],
    ownOffers: [],
    meta: {},
    ownMeta: {},
    legals: [],
    legalBuyers: [],
    legalSuppliers: [],
    legalOptions: [],
  },
  getters: {},
  mutations: {
    SET_OFFERS(state, offers = []) {
      state.offers = offers;
    },
    SET_OWN_OFFERS(state, offers = []) {
      state.ownOffers = offers;
    },
    SET_CURRENT_OFFER(state, offer = {}) {
      state.currentOffer = offer;
    },
    SET_CURRENT_OFFER_MARGIN(state, offerResVerify = {}) {
      state.currentOffer.margin = offerResVerify.margin;
      state.currentOffer.verified = offerResVerify.verified;
      state.currentOffer.status = offerResVerify.status;
    },
    SET_META(state, meta = {}) {
      state.meta = meta;
    },
    SET_OWN_META(state, meta = {}) {
      state.ownMeta = meta;
    },
    SET_LEGALS(state, legals) {
      state.legals = legals;
    },
    UPDATE_LEGALS(state, legal) {
      let oldLegal = state.legals.find(member => member.id === legal.id);
      oldLegal = legal;
    },
    SET_LEGAL_OPTIONS(state, options) {
      state.legalOptions = options;
    },
    SET_LEGAL_BUYERS(state, options) {
      state.legalBuyers = options;
    },
    SET_LEGAL_SUPPLIERS(state, options) {
      state.legalSuppliers = options;
    },
  },
  actions: {
    async fetch({ commit }, reqMeta) {
      const { data } = await axios.get(
        endpoints[reqMeta.fetchParam],
        {
          params: reqMeta.data,
        },
      );

      commit('SET_META', data.meta);
      commit('SET_OFFERS', data.data);
    },
    async fetchOffers({ commit }, reqMeta) {
      const { data } = await axios.get(
        endpoints[reqMeta.fetchParam],
        {
          params: reqMeta.data,
        },
      );

      commit('SET_OWN_META', data.meta);
      commit('SET_OWN_OFFERS', data.data);
    },
    async acceptOffer(_, { offerId, buyerId }) {
      if (buyerId) {
        const { data } = await axios.post(
          `${endpoints.accept}/${offerId}`,
          { legal_id: buyerId },
        );
        return data?.data?.deal?.id || null;
      }
      const { data } = await axios.post(
        `${endpoints.accept}/${offerId}`,
      );
      return data?.data?.deal?.id || null;
    },
    async generateDocuments({ commit }, id) {
      const { data } = await axios.post(`offers/legal-members/${id}/generate`);
      commit('SET_CURRENT_OFFER', data.data);
      Vue.$toast({
        component: ToastificationContent,
        props: {
          title: 'Documents generated successfully',
          icon: 'CheckCircleIcon',
          variant: 'success',
        },
      });
    },
    async fetchOfferById({ commit, dispatch }, { offerId, isAdmin = true }) {
      const { data } = await axios.get(`${ApiEndpoints.offers}/${offerId}/show`);

      commit('SET_CURRENT_OFFER', data.data);

      if (isAdmin) dispatch('fetchLegalMembers', offerId);
    },
    async fetchLegalOptions({ commit }) {
      const { data } = await axios.get(ApiEndpoints.legals);

      commit('SET_LEGAL_OPTIONS', data.data.legals);
    },
    async fetchLegalBuyers({ commit }) {
      const { data } = await axios.get(`${ApiEndpoints.legals}/buyers`);

      commit('SET_LEGAL_BUYERS', data.data);
    },
    async fetchLegalSuppliers({ commit }) {
      const { data } = await axios.get(`${ApiEndpoints.legals}/suppliers`);

      commit('SET_LEGAL_SUPPLIERS', data.data);
    },
    async fetchLegalMembers({ commit }, offerId) {
      const { data } = await axios.get(`${endpoints.legals}/${offerId}`);

      commit('SET_LEGALS', data.data.members);
    },
    async updateLegalMembers({ commit }, { id, payload }) {
      commit('SET_LEGALS', payload);
      const membersId = payload.map(member => member.id);
      await axios.post(`${endpoints.legals}/${id}/sort`, { ids: membersId });
    },
    async createNewOfferLegal({ dispatch }, { id, payload }) {
      await axios.post(`${endpoints.legals}/${id}`, payload);
      await dispatch('fetchLegalMembers', id);
    },
    async updateOfferLegalMargin({ commit }, { id, margin, offerId }) {
      const { data } = await axios.put(`${endpoints.legals}/${id}`, { margin });
      commit('UPDATE_LEGALS', data.data.member);
    },
    async deleteOfferLegal({ dispatch }, { id, offerId }) {
      await axios.delete(`${endpoints.legals}/${id}`);
      await dispatch('fetchLegalMembers', offerId);
    },
  },
};
