const prefix = 'WTW CRM';

export function getPageTitleWithPrefix($route) {
  const title = getPageTitle($route);

  if (!title) {
    return prefix;
  }

  return prefix + (title ? ` - ${title}` : '');
}

export function getPageTitle($route) {
  return typeof $route.meta.pageTitle === 'function' ? $route.meta.pageTitle($route) : $route.meta.pageTitle;
}
