import axios from '@/libs/axios';
import { ApiEndpoints } from '@/settings';
import updateDealHandler from '@/store/deal/utils';

export default {
  namespaced: true,
  state: {
    deal: {},
    comments: {},
    legals: [],
    chatBuyer: {
      messages: [],
    },
    chatSupplier: {
      messages: [],
    },
  },
  getters: {
    chatBuyer: state => state.chatBuyer,
    chatSupplier: state => state.chatSupplier,
  },
  mutations: {
    SET_DEAL(state, deal = {}) {
      state.deal = deal;
    },
    SET_COMMENTS(state, comments) {
      state.comments = comments;
    },
    SET_LEGALS(state, legals) {
      state.legals = legals;
    },
    UPDATE_DEAL_DOCUMENTS(state, documents) {
      state.deal.uploaded_documents = documents;
    },
    UPDATE_DEAL_LEGAL_DOCUMENTS(state, documents) {
      state.deal.legal.documents = documents;
    },
    SET_CHAT_MESSAGES(state, chatData) {
      if (chatData.type === 'buyer') {
        state.chatBuyer.pagination = chatData.pagination;
        state.chatBuyer.type = chatData.type;
        const chatArr = [...state.chatBuyer.messages];
        chatData.messages.forEach(item => {
          chatArr.unshift(item);
        });
        state.chatBuyer.messages = chatArr;
      } else {
        state.chatSupplier.pagination = chatData.pagination;
        state.chatSupplier.type = chatData.type;
        const chatArr = [...state.chatSupplier.messages];
        chatData.messages.forEach(item => {
          chatArr.unshift(item);
        });
        state.chatSupplier.messages = chatArr;
      }
    },
    SET_ONE_MESSAGE_TO_CHAT(state, message) {
      if (message.type === 'buyer') {
        state.chatBuyer.messages.push(message);
      } else {
        state.chatSupplier.messages.push(message);
      }
    },
  },
  actions: {
    async fetch({ commit }, dealId) {
      try {
        // commit('app/SHOW_LOADER', null, { root: true });
        const { data } = await axios.get(`${ApiEndpoints.deals}/${dealId}/show`);

        commit('SET_DEAL', data.data.deal);
      } catch (e) {
        console.log(e);
      }
    },
    async fetchComments({ commit }, { dealId, type }) {
      const { data } = await axios.get(`${ApiEndpoints.deals}/${dealId}/messages/by-type?type=${type}&per_page=10&page=1`);

      commit('SET_COMMENTS', data.data.deal);
    },
    async fetchLegals({ commit }) {
      const { data } = await axios.get(`${ApiEndpoints.legals}`);

      commit('SET_LEGALS', data.data.legals);
    },
    async closeDeal({ commit }, id) {
      const { data } = await axios.post(`${ApiEndpoints.deals}/${id}/close`);
      updateDealHandler(data, commit);
    },
    async createProblem({ commit }, { id, message }) {
      const { data } = await axios.post(`${ApiEndpoints.deals}/${id}/problem`, { message });
      updateDealHandler(data, commit);
    },
    async solveProblem({ commit }, id) {
      const { data } = await axios.post(`${ApiEndpoints.deals}/${id}/problem/solve`);
      updateDealHandler(data, commit);
    },
    async setGoodsSent({ commit }, id) {
      const { data } = await axios.post(`/deals/${id}/goods/sent`);
      updateDealHandler(data, commit);
    },
    async setGoodsReceived({ commit }, id) {
      const { data } = await axios.post(`/deals/${id}/goods/received`);
      updateDealHandler(data, commit);
    },
    async setPaymentSent({ commit }, { payload, id }) {
      const { data } = await axios.post(`/deals/${id}/payment/sent`, payload);
      commit('offers/SET_LEGALS', data.data.deal.offer.legal_members, { root: true });
      updateDealHandler(data, commit);
    },
    async setPaymentReceived({ commit }, { payload, id }) {
      const { data } = await axios.post(`/deals/${id}/payment/received`, payload);
      commit('offers/SET_LEGALS', data.data.deal.offer.legal_members, { root: true });
      updateDealHandler(data, commit);
    },
    async setPrice({ commit }, { payload, id }) {
      const { data } = await axios.post(`/deals/${id}/delivery/price`, payload);
      updateDealHandler(data, commit);
    },
    async setClearancePrice({ commit }, { payload, id }) {
      const { data } = await axios.post(`/deals/${id}/clearance/price`, payload);
      updateDealHandler(data, commit);
    },
    async sendInvoiceToBuyer(_, id) {
      await axios.post(`/deals/${id}/send-invoice-to-buyer`);
    },
    async setDeliveryOptions({ commit }, { payload, id, type }) {
      const { data } = await axios.post(`/deals/${id}/${type}`, payload);
      updateDealHandler(data, commit);
    },
    async setDeliveryStatus({ commit }, { id, deliveryStatus: delivery_status }) {
      const { data } = await axios.patch(`/deals/${id}/delivery-status`, { delivery_status });
      updateDealHandler(data, commit);
    },
    async generateDocuments({ commit }, id) {
      const { data } = await axios.post(`offers/legal-members/${id}/generate`);
      updateDealHandler(data, commit, 'Documents generated successfully');
    },
    async uploadDocument({ commit, state }, { formData }) {
      const { id } = state.deal;
      const { data } = await axios.post(`${ApiEndpoints.dealsV1}/${id}/documents`, formData);

      commit('UPDATE_DEAL_DOCUMENTS', data.uploaded_documents);

      if (data?.legal?.documents) {
        commit('UPDATE_DEAL_LEGAL_DOCUMENTS', data.legal.documents);
      }
    },
    setLegalDocuments({ commit }, { documents }) {
      commit('UPDATE_DEAL_LEGAL_DOCUMENTS', documents);
    },
    async uploadInvoice({ commit, state }, { formData }) {
      const { id } = state.deal;
      const { data } = await axios.post(`/deals/${id}/documents`, formData);
      commit('UPDATE_DEAL_DOCUMENTS', data.data.uploaded_documents);
    },
    async getDocument({ state }, documentId) {
      const { id } = state.deal;
      return axios.post(`${ApiEndpoints.dealsV1}/${id}/documents/download`, { document_id: documentId }, {
        responseType: 'blob',
      });
    },
    async removeDocument({ commit }, { documentId }) {
      const data = await axios.post(`${ApiEndpoints.documents}/delete`, { document_id: documentId });
      // updateDealHandler(data, commit);
    },
    async updateBuyer({ commit }, { dealId, id }) {
      const { data } = await axios.post(`/deals/${dealId}/update-buyer`, { legal_id: id });
      updateDealHandler(data, commit);
    },
    async sentChatMessage({ commit }, { dealId, type, message }) {
      return axios.post(`${ApiEndpoints.deals}/${dealId}/messages?type=${type}&message=${message}`, { type, message })
        .then(response => {
          commit('SET_ONE_MESSAGE_TO_CHAT', response.data.data.message);
        });
    },

    async fetchMessages({ commit }, { dealId, type, page }) {
      return axios.get(`${ApiEndpoints.deals}/${dealId}/messages/by-type?type=${type}&per_page=10&page=${page}`)
        .then(response => {
          commit('SET_CHAT_MESSAGES', {
            messages: response.data.data,
            pagination: response.data.meta,
            type,
          });
        });
    },
    async readMessagesChat(_, { dealId, type, ids }) {
      return await axios.post(`${ApiEndpoints.deals}/${dealId}/messages/read`, {
        ids,
        type,
      });
    },
    async getCountUnreadChat(_, { dealId, type }) {
      return await axios.get(`${ApiEndpoints.deals}/${dealId}/messages/by-type/new-count?type=${type}`)
        .then(response => response.data.data.count);
    },
    async fetchList(_, req) {
      const route = req.type ? `/deals/${req.type}` : '/deals';
      const { params } = req;
      return await axios.get(route, { params });
    },
  },
};
