import { $themeBreakpoints } from '@themeConfig';
import axios from '@/libs/axios';

export default {
  namespaced: true,
  state: {
    windowWidth: 0,
    shallShowOverlay: false,
    availableCountries: [],
    isLoading: false,
  },
  getters: {
    getCountryById: state => id => state.availableCountries.find(country => country.id === id),
    currentBreakPoint: state => {
      const { windowWidth } = state;
      if (windowWidth >= $themeBreakpoints.xl) return 'xl';
      if (windowWidth >= $themeBreakpoints.lg) return 'lg';
      if (windowWidth >= $themeBreakpoints.md) return 'md';
      if (windowWidth >= $themeBreakpoints.sm) return 'sm';
      return 'xs';
    },
  },
  mutations: {
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val;
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay;
    },
    SET_COUNTRIES(state, countries) {
      state.availableCountries = countries;
    },
    SHOW_LOADER(state) {
      state.isLoading = true;
    },
    HIDE_LOADER(state) {
      state.isLoading = false;
    },
  },
  actions: {
    async setCountries({ commit }) {
      const { data } = await axios.get('/getCountryAvailable');

      commit('SET_COUNTRIES', data.data.countries);
    },
    showLoader({ commit }) {
      commit('SHOW_LOADER');
    },
    hideLoader({ commit }) {
      commit('HIDE_LOADER');
    },
  },
};
