import axios from '@/libs/axios';
import { ApiEndpoints } from '@/settings';
import { hasAdminRole, hasBuyerRole, hasSupplierRole } from '@/constants/roles.js';
import router from '../../router/index';

const endpoints = {
  updateAvatar: `${ApiEndpoints.user}/profile/avatar`,
  profile: `${ApiEndpoints.user}/profile`,
  contract: `${ApiEndpoints.user}/profile/contract`,
  signAgreement: `${ApiEndpoints.user}/profile/sign-agreement`,
  sign: `${ApiEndpoints.user}/profile/sign`,
};

export default {
  namespaced: true,
  state: {
    isProfileDisabled: false,
    profile: null,
  },
  getters: {
    profile: state => state.profile,
    isAdmin: state => hasAdminRole(state.profile?.roles),
    isBuyerOnly: state => hasBuyerRole(state.profile?.roles) && !hasSupplierRole(state.profile?.roles),
    isSupplierOnly: state => hasSupplierRole(state.profile?.roles) && !hasBuyerRole(state.profile?.roles),
    isBuyerAndSupplier: state => hasBuyerRole(state.profile?.roles) && hasSupplierRole(state.profile?.roles),
  },
  mutations: {
    UPDATE_PROFILE(state, profile) {
      state.profile = profile;
    },
    UPDATE_AVATAR(state, { avatar }) {
      state.profile.profile.avatar_url = avatar;
    },
    SET_PROFILE(state, profile) {
      state.profile = profile;
    },
    SET_FETCHED_STATUS(state, status) {
      state.isProfileDisabled = status;
    },
  },
  actions: {
    async fetch({ commit }) {
      try {
        const { data } = await axios.get(endpoints.profile);

        commit('SET_PROFILE', data.data.user);
        // commit('SET_FETCHED_STATUS', true);
      } catch {
        localStorage.removeItem('Authorization');
        if (router.currentRoute.name !== 'auth-login') {
          await router.push({ name: 'auth-login' });
        }
      }
    },
    async update({ commit }, dataToUpdate) {
      // TODO: test when error gone
      const { data: { data } } = await axios.post(`${endpoints.profile}`, dataToUpdate);

      commit('UPDATE_PROFILE', data.user);
    },
    async verify() {
      await axios.post('/send-verify-email');
    },
    async updateAvatar({ commit }, avatar) {
      const { data } = await axios.post(`${endpoints.updateAvatar}`, avatar);

      commit('UPDATE_AVATAR', data.data);
    },
    async logout({ commit }, withLogout = true) {
      try {
        if (withLogout) {
          await axios.post('/logout');
        }
      } finally {
        localStorage.clear();
        commit('SET_PROFILE', null);
      }
    },
    async getAgreement() {
      const response = await axios.get(endpoints.signAgreement, {
        responseType: 'blob',
      });

      return response.data;
    },
    async getContract() {
      const response = await axios.get(endpoints.contract, {
        responseType: 'blob',
      });

      return response.data;
    },
    async getSign() {
      const response = await axios.get(endpoints.sign, {
        responseType: 'blob',
      });

      return response.data;
    },
    async addSign({ dispatch }, { file, userId }) {
      const formData = new FormData();
      formData.append('sign', file);
      formData.append('user_id', userId);

      await axios.post(endpoints.sign, formData);
      await dispatch('fetch');
    },
    async addSignAgreement({ dispatch }, { file, userId }) {
      const formData = new FormData();
      formData.append('sign', file);
      formData.append('user_id', userId);

      await axios.post(endpoints.signAgreement, formData);
      await dispatch('fetch');
    },
    async addContract({ dispatch }, { file, userId }) {
      const formData = new FormData();
      formData.append('sign', file);
      formData.append('user_id', userId);

      await axios.post(endpoints.contract, formData);
      await dispatch('fetch');
    },
  },
};
