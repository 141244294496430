import Vue from 'vue';
import VueRouter from 'vue-router';

import { getPageTitleWithPrefix } from '@/utils/pageTitle.js';
import apps from './routes/apps';
import pages from './routes/pages';
import trading from './routes/trading';
import { removeSplashScreen } from '@/utils/splashScreenHelper';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    ...apps,
    ...pages,
    ...trading,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach((to, from) => {
  if (['auth-login', 'auth-register', 'auth-forgot-password', 'auth-reset-password'].includes(router.currentRoute.name)) {
    removeSplashScreen();
  }

  Vue.nextTick(() => {
    document.title = getPageTitleWithPrefix(to);
  });
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!localStorage.Authorization) {
      next({ name: 'auth-login' });
    } else {
      next();
    }
  } else {
    next();
  }

  if (to.matched.some(record => record.meta.redirectIfLoggedIn)) {
    if (localStorage.Authorization) {
      next({ path: from.path });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
