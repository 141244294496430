import axios from '@/libs/axios';

export default {
  namespaced: true,
  state: {
    groups: [],
  },
  mutations: {
    SET_GROUPS(state, groups = []) {
      state.groups = groups;
    },
  },
  actions: {
    async fetch({ commit }) {
      const { data } = await axios.get('/product-groups');

      commit('SET_GROUPS', data.data);
    },
  },
};
