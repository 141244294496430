export const RUB_ID = 0;
export const USD_ID = 1;
export const BYN_ID = 2;
export const EUR_ID = 3;
export const HKD_ID = 4;
export const AED_ID = 5;
export const CNY_ID = 6;
export const PLN_ID = 7;
export const GBP_ID = 8;

export const currencyOptions = [
  {
    value: RUB_ID,
    label: 'RUB',
  },
  {
    value: USD_ID,
    label: 'USD',
  },
  {
    value: BYN_ID,
    label: 'BYN',
  },
  {
    value: EUR_ID,
    label: 'EUR',
  },
  {
    value: HKD_ID,
    label: 'HKD',
  },
  {
    value: AED_ID,
    label: 'AED',
  },
  {
    value: CNY_ID,
    label: 'CNY',
  },
  {
    value: PLN_ID,
    label: 'PLN',
  },
  {
    value: GBP_ID,
    label: 'GBP',
  },
];

export const SYMBOLS = {
  [RUB_ID]: '₽',
  [USD_ID]: '$',
  [BYN_ID]: 'BYN',
  [EUR_ID]: '€',
  [HKD_ID]: 'HK$',
  [AED_ID]: 'DHS',
  [CNY_ID]: '¥',
  [PLN_ID]: 'zł',
  [GBP_ID]: '£',
};

export const getCurrencySymbol = currency => SYMBOLS[currency] ?? 'unknown';
