import Vue from 'vue';
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue';
import VueCompositionAPI from '@vue/composition-api';
import i18n from '@/libs/i18n';

// Global Components
import './global-components';

// 3rd party plugins
import '@/libs/portal-vue';
import '@/libs/toastification';
import { resolveDate } from '@/utils/dateUtils';
import { getCurrencySymbol } from '@/constants/currency';
import Multiselect from 'vue-multiselect';
import vSelect from 'vue-select';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import { avatarText } from '@core/utils/filter';
import App from './App.vue';
import store from './store';
import router from './router';

// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);

Vue.filter('formatDate', resolveDate);
Vue.filter('currencySymbol', getCurrencySymbol);
Vue.filter('avatarText', avatarText);

Vue.component('multiselect', Multiselect);
Vue.component('v-select', vSelect);

// Composition API
Vue.use(VueCompositionAPI);

// import core styles
require('@core/scss/core.scss');

// import assets styles
require('@/assets/scss/style.scss');

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app');
