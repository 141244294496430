import Vue from 'vue';
import axios from '@/libs/axios';
import { ApiEndpoints } from '@/settings';
import { hasVerifiedRole } from '@/constants/roles.js';

const endpoints = {
  create: `${ApiEndpoints.users}/create`,
  verify: `${ApiEndpoints.users}/verify`,
  update: `${ApiEndpoints.users}/update`,
  paginate: `${ApiEndpoints.users}/paginate`,
};

export default {
  namespaced: true,
  state: {
    currentUser: {},
    users: [],
    meta: {},
  },
  getters: {
    getUserById: state => id => state.users.find(user => user.id === id),
  },
  mutations: {
    SET_USERS(state, users = []) {
      state.users = users;
    },
    ADD_USER(state, user) {
      Vue.set(state.users, state.users.length, user.data.user);
    },
    UPDATE_USER(state, { userId, user }) {
      const userToUpdateIndex = state.users.findIndex(({ id }) => (id === userId));
      if (userToUpdateIndex) {
        Vue.set(state.users, userToUpdateIndex, { ...state.users[userToUpdateIndex], ...user });
      }
    },
    SET_CURRENT_USER(state, user) {
      state.currentUser = user;
    },
    SET_META(state, meta = {}) {
      state.meta = meta;
    },
  },
  actions: {
    async create({ commit }, user) {
      try {
        const { data } = await axios.put(endpoints.create, user);

        commit('ADD_USER', data);
        return data.status;
      } catch (error) {
        console.log(error);
      }
      return false;
    },
    async fetch({ commit }, reqMeta) {
      const { data } = await axios.get(
        endpoints.paginate,
        {
          params: reqMeta,
        },
      );

      commit('SET_META', data.meta);
      commit('SET_USERS', data.data);
    },
    async fetchUserById({ commit }, id) {
      try {
        const { data } = await axios.get(`/users/show/${id}`);

        commit('SET_CURRENT_USER', data.data.user);
      } catch (error) {
        console.log(error);
      }
    },
    async verify(_, { id, roles }) {
      const { data: { data } } = await axios.post(
        `${endpoints.verify}/${id}`,
        {
          is_verified: Number(!hasVerifiedRole(roles)),
        },
      );

      return data.user;
    },
    async verifyEmail(_, id) {
      await axios.post(`/users/resend-verify-email/${id}`);
    },
    async update({ commit }, { userId, data }) {
      // TODO: test when error gone
      const response = await axios.post(`${endpoints.update}/${userId}`, data);

      commit('UPDATE_USER', { user: response.data.data.user, userId });
    },
  },
};
