import i18n from '@/libs/i18n';

export const VERIFIED_ROLE = 'verified';
export const BUYER_ROLE = 'buyer';
export const SUPPLIER_ROLE = 'supplier';
export const BOOKER_ROLE = 'booker';
export const MANAGER_ROLE = 'manager';
export const SUPER_ADMIN_ROLE = 'super-admin';

export const defaultRolesOptions = [
  { label: i18n.t(BUYER_ROLE), value: BUYER_ROLE },
  { label: i18n.t(SUPPLIER_ROLE), value: SUPPLIER_ROLE },
];

export const hasRole = (roles, foundRoles) => !!roles?.some(({ name }) => foundRoles.includes(name));
export const hasVerifiedRole = roles => hasRole(roles, [VERIFIED_ROLE]);
export const hasManagerRole = roles => hasRole(roles, [MANAGER_ROLE]);
export const hasSuperAdminRole = roles => hasRole(roles, [SUPER_ADMIN_ROLE]);
export const hasAdminRole = roles => hasRole(roles, [MANAGER_ROLE, SUPER_ADMIN_ROLE]);
export const hasBuyerRole = roles => hasRole(roles, [BUYER_ROLE]);
export const hasSupplierRole = roles => hasRole(roles, [SUPPLIER_ROLE]);
