import i18n from '@/libs/i18n';

export default [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/pages/Home.vue'),
    meta: {
      pageTitle: i18n.t('home'),
      requiresAuth: true,
      breadcrumb: [
        {
          text: i18n.t('home'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/account-settings',
    name: 'account-settings',
    component: () => import('@/views/pages/accountSettings/AccountSetting.vue'),
    meta: {
      pageTitle: i18n.t('myAccount'),
      requiresAuth: true,
      breadcrumb: [
        {
          text: i18n.t('myAccount'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/pages/authentication/Login.vue'),
    meta: {
      pageTitle: i18n.t('login'),
      redirectIfLoggedIn: true,
      layout: 'full',
    },
  },
  {
    path: '/register',
    name: 'auth-register',
    component: () => import('@/views/pages/authentication/Register.vue'),
    meta: {
      pageTitle: i18n.t('register'),
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/forgot-password',
    name: 'auth-forgot-password',
    component: () => import('@/views/pages/authentication/ForgotPassword.vue'),
    meta: {
      pageTitle: i18n.t('forgotPassword'),
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/reset-password',
    name: 'auth-reset-password',
    component: () => import('@/views/pages/authentication/ResetPassword.vue'),
    meta: {
      pageTitle: i18n.t('notFound'),
      layout: 'full',
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      pageTitle: i18n.t('notFound'),
      layout: 'full',
    },
  },
];
